<template>

    <div id="paywall_tailwind">

        <form
            class="mt-12 bg-white"
            @submit.prevent="debounce_submit_process">

            <FormInputField
                :class="{
                    'mb-1': is_logged_in,
                    'mb-4': ! is_logged_in
                }"
                placeholder="E-mail"
                type="text"
                :is_required="true"
                :is_autofocus="true"
                autocomplete="email"
                v-model="signup_form.email"
                @input="debounce_email_change"
                :is_disabled="is_logged_in"
            />

            <messages
                class="bg-white w-full mt-1"
                :types="['email_check']"
            ></messages>

            <p class="mb-4"
                v-if="is_logged_in">Du er logged ind. Vi har derfor udfyldt email for dig.</p>

            <div class="flex flex-col sm:flex-row gap-4 ">

                <FormInputField
                    class="mb-4"
                    placeholder="Kodeord"
                    type="password"
                    :is_required="true"
                    autocomplete="current-password"
                    v-model="signup_form.password"
                    v-if="! is_logged_in"
                />

                <FormInputField
                    class="mb-4"
                    placeholder="Bekræft kodeord"
                    type="password"
                    :is_required="true"
                    autocomplete="current-password"
                    v-model="signup_form.password_confirmation"
                    v-if="! is_logged_in && ! is_email_known"
                />

            </div>

            <div class="flex flex-col sm:flex-row gap-4">

                <FormInputField
                    class="mb-4"
                    placeholder="Navn"
                    type="text"
                    :is_required="true"
                    autocomplete="first-name"
                    v-model="signup_form.full_name"
                    v-if="! is_email_known"
                />

                <FormInputField
                    class="mb-4"
                    placeholder="Telefonnummer"
                    type="number"
                    :is_required="true"
                    autocomplete="mobile"
                    v-model="signup_form.phone"
                    v-if="! is_email_known"
                />

            </div>

            <div class="flex flex-col sm:flex-row gap-4"
                 v-if="is_municipality_member_shown && ! is_email_known">

                <label class="inline-flex items-center mb-4 cursor-pointer"
                       for="is_municipality_member">

                    <input
                        class="form-checkbox h-5 w-5 text-[#1479FB] focus:outline-none focus:ring-none border border-1 border-[#C1C1C1]"
                        id="is_municipality_member"
                        type="checkbox"
                        v-model="signup_form.is_municipality_member">

                    <p class="ml-2 text-gray-700">

                        Er du byrådsmedlem?

                    </p>

                </label>

            </div>

<!--            <div class="flex flex-col sm:flex-row gap-4">-->

<!--                <FormInputField-->
<!--                    class="mb-4"-->
<!--                    placeholder="Jobtitel"-->
<!--                    type="text"-->
<!--                    :is_required="true"-->
<!--                    autocomplete="job"-->
<!--                    v-model="signup_form.job_position"-->
<!--                    v-if="! is_email_known"-->
<!--                />-->

<!--                <FormInputField-->
<!--                    class="mb-4"-->
<!--                    placeholder="Firma / institution"-->
<!--                    type="text"-->
<!--                    :is_required="true"-->
<!--                    autocomplete="company"-->
<!--                    v-model="signup_form.company_name"-->
<!--                    v-if="! is_email_known"-->
<!--                />-->

<!--            </div>-->

<!--            <div class="flex flex-col sm:flex-row gap-4">-->

<!--                <FormInputField-->
<!--                    class="mb-4"-->
<!--                    placeholder="CVR-nr."-->
<!--                    type="text"-->
<!--                    :is_required="false"-->
<!--                    autocomplete="cvr_number"-->
<!--                    v-model="signup_form.cvr_number"-->
<!--                    v-if="! is_email_known"-->
<!--                />-->

<!--                <FormInputField-->
<!--                    class="mb-4"-->
<!--                    placeholder="EAN-nr."-->
<!--                    type="text"-->
<!--                    :is_required="false"-->
<!--                    autocomplete="ean_number"-->
<!--                    v-model="signup_form.ean_number"-->
<!--                    v-if="! is_email_known"-->
<!--                />-->

<!--            </div>-->

            <label class="inline-flex items-center mb-4 cursor-pointer"
                for="is_terms_accepted">

                <input
                    class="form-checkbox h-5 w-5 text-[#1479FB] focus:outline-none focus:ring-none border border-1 border-[#C1C1C1]"
                    id="is_terms_accepted"
                    type="checkbox"
                    v-model="signup_form.is_terms_accepted">

                <p class="ml-2 text-gray-700">

<!--                    Jeg accepterer <a href="/politikker" class="text-[#1479FB]" target="_blank">vilkårene</a>-->
                    Jeg accepterer <a :href="site.terms_link" class="text-[#1479FB]" target="_blank">vilkårene</a>

                </p>

            </label>

            <button class="hover:bg-white border-2 hover:border-2 w-full py-3 px-4 text-white text-[20px] leading-tight"
                :class="`bg-${ site.color } border-${ site.color } hover:text-${ site.color }`"
                type="submit">

                <span class="hidden bg-green bg-red bg-purple bg-gray bg-black bg-blue bg-gold"></span>

                <span class="hidden hover:text-blue hover:text-green hover:text-red hover:text-black hover:text-purple hover:text-gray hover:text-gold"></span>

                <span class="hidden border-blue border-green border-black border-purple border-red border-gray border-gold"></span>

                <p class="font-medium">

                    Ja tak - bestil en gratis prøveperiode

                </p>

            </button>

        </form>


        <messages
            class="bg-white w-full mt-4"
            :types="['signup_form', 'authentication']"
        ></messages>

    </div>

</template>

<script>
import FormInputField from "../components/FormInputField.vue";

export default {

    name: "SignupFormView",

    components: {
        FormInputField
    },

    props: {
        _site: {
            type: String,
            default: false
        }
    },

    async created() {

        let
            profile_form = await this.get_customer_profile(),
            default_form = this.signup_form
        ;

        if ( this._site ) {

            this.site = JSON.parse(this._site);

        }

        this.signup_form = {
            ...default_form,
            ...profile_form,
            token: localStorage.getItem('paywall.token'),
            site_id: this.site.id
        };

    },

    computed: {

        is_municipality_member_shown() {

            return this.site.slug === 'nb_kommune';

        }

    },

    methods: {

        debounce_submit_process: _.debounce(function() {

            this.submit_process();

        }, 500),

        async submit_process() {

            let
                is_success
            ;

            is_success = await this.forms_submit(
                'signup_form',
                'We have created your subscription and are signing you in now'
            );

            if ( ! is_success )
                return false;

            if ( this.signup_form.password ) {

                await this.login({
                    email: this.signup_form.email,
                    password: this.signup_form.password
                });

            }

            this.push_success_message (this.__('We will direct you to the frontpage'), true, 'signup_form' );

            setTimeout(() => {

                if ( this.site.slug === 'nb_data' ) {

                    location.pathname = '/dokumenter';

                }
                else {

                    location.pathname = '/';

                }

            },4000);

        },

        debounce_email_change: _.debounce(function() {

            this.email_change();

        }, 500),

        async email_change() {

            if ( ! this.signup_form.email )
                return;

            let
                response
            ;

            response = await window.paywall.axios.post('/forms/email_check', {
                email: this.signup_form.email
            });                                                         console.log('Email exists', response);

            if (
                response.hasOwnProperty('exists')
                && response.exists === true
            ) {

                this.is_email_known = true;

                this.push_error_message('Vi har fundet din profil. Log venligst ind for at fortsætte', false,'email_check');

            }
            else {

                this.clear_messages(0, 'email_check');

                this.is_email_known = false;

            }

        }

    }

}
</script>

<style scoped>

</style>
